//Language constants
import {direction, GetAllDevelopers, GetAllProjects, GetAllProperties, Id, language} from "../types/types";

export const CURSORS =  {
	// NORMAL: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkFEQId/Ed1zgAAAnlJREFUWMNjELxq4GR99ssXwXMGP22kiosZGBgYGBgYGRmGChASMjCwsfn/H05PN5hvI7B5M2+l6RPTJ8LCA+0+QoAJQ6SaYSKDjo8P67LfF9lVzp8XjNbfZbPP2nqgHUq8B2DgM0M1g4msLCMDgylD3oEDgv/1/1v/b2iAamMiznjaA0ZY0iFaRytDPsOVLVt+P2J1/XklIeFz+2mZ0zJv3w6UB0gPSVgSm/R7FtvDCxcGOomRnxTYGTYxlsvIDHQSo9yi7YyODAIsLIzCjIaMtvX1gqf1X9pY79wp5qTTar5MXHzwewANMLozujMwurj8Ocnyk0X+zBlBAb131rttbIaMB+AAlsS8GRkZC/fvp1USo31apXESo3t5Tu0kNnAVEnoSW27Qaz21pGToeAAGYEns5H9dRs2KCsmZxsbGxlxcxGpnIdfe/3v+r2G49/w5owfj1f87L11iOMTwj+H269f/+RiWMeq/ecN49X8nQ92bNwxxTM7/5V68YJzBIMkw6/Xrf9/+BzNtf/OGue//7P/X37z5J8K++ZfVixfvJpxSPaX66ROp7iC9KfGTwe9/55MnDHZ/LVisLC3fbb/sdZDxyROqxwyRgPgk5MWQzeD47h2jBvPWf1dcXQfa4TBAMAn97/lfw/Dgxw+Gov9O/+38/d99uJB63PXGjYF2OGEPqDAkMlT++8f4mGkt46+YmHcfzocccT1yZKAdjA5wJ6EMBj+Gs4WF7+rPLz8cuHbtQDsUF8CMgSf/LRiKOjrecV1UPBI0adJAO5AggPeFbxist163eDFEdOh06ln+/2fQ+l8fHf1e49fv9+/XrIEIk1CsjgLKAAB93QjmKXlGPwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOS0wNVQxNzowMjoyOSswMDowMIyaN0QAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDktMDVUMTc6MDI6MjkrMDA6MDD9x4/4AAAAAElFTkSuQmCC",
	// ADD: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkFEDY4Das4SQAAAwZJREFUWMPVl11Ik1EYx//PNucMS9/NLmtYqBTU1mxm+E4tEJGCNYiELiIo7cusBJMuSiqrZRTpjQVRIESkkcmIjL6oOUvW+lgfWC0yLGrOzRZSLdo5XYS7EGRO92G/y/Nynvf/f57/4bwvlGXaLaLo8ymd2m+iaDYDRbyIy2T4T5CgF72AIKAYxUBdnfDSX/nnvc2WdnjxicK0zMxECwxvYAxUCDttzMuTeknDOux2QdD0GnavWpVooRM2EKKV9uKASkUqauEKi0W4pGkX5U1NQK4uV5eUlGjh4Q2M4sMzdBPRDjqCvOpq4W7wR0rr9IlYeANjoLVQYKteP10iFrGBEONGbGHbwja5PF4GSKnUakWR82gV5FfwC2fsdmZjmyQF5eX+/c7aB/4PH2JlYPITGK8jcY5Y1A2EiFPEoh6hsKjxiTf29CRlsDq+1WRy33LW9jgHBydbLnYTGMsuzEPV7994i+3gc+cGGnAezTk5Uy0b+QRkOMhbbt7ERb6IzFYr15KNZrvdkqP4hUyPhw1yNV80NCR9i2oMeDzBd7IZila3e/ib48ntO35/tPsy4Y82vgcmVNy7N7x/5OMsk9GIEpfxhisQiLagSAkfoWVo5vUvXuCktFPRajIBrupYCFd1alYWqFasULZoL4jpFsvUDayGnNf290uWSPdIrpeWxioCo7B+7KQFWVnwciN/k58/eQMbeCMOeb30ShpkX8vKho46HNbTX77ESvhUCZ0Bfp5fhfrnT7TDBRiNPofD8XBLX1+0X6jiGl7Ai4tZM66RITt7dJ1KCbzGYODpOEbzFQphpqZDFCsrQ8/NkhpuCAb/qAPHg9s7Or4/er3u0RyfT4bN/CmUz59L7tM5yquv9158VmFdabPFqmOM0S4a2LePuhAA9PpQA3W4TEPJybQaa1CVkoIs6kK32RzaWM7OUglj0vLkVNmpz5+xHAC6umKlM2KEpn8dVzZofAVuj2ei++J3kcWI6WNgPY3QUsZ4Om2jBsYSLSdiUh/n6op4Rkaif5Dizl86rkTmTGXa6wAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOS0wNVQxNjo1NDo1NiswMDowMNHISzQAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDktMDVUMTY6NTQ6NTYrMDA6MDCglfOIAAAAAElFTkSuQmCC",
	// DELETE: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkFEwcnRLiMVwAAApdJREFUWMPtlE9Ik2Ecx7/PNt2q0QIry/5g0mQHYUYZFUhBob5bgX8vuciUd4MO7RAVUSKWF7tMurWFNRAx04FkJR5E8DBnGYFCBw0RXzfY5nQw0Znu1yE1UtSZm5vg5/jA73m/n+f35YXrAqfhVbOzrgTuFv+wocH97jLdbZbLsUMQkQ2EbKmURuHFZGnpgmmv8ldff7/Qk/PN8FStjnXADQVWHpANSvjT08U6sT10qLfX+Zb7yj82GmMdNGyBZRE72jEokyEHlfDW1Tnb8ib4rJYW38S1q3peoYh18A0FVpHFdMgsKgpqE0ZJ0dcXLxULX2CReKvYpgWWRVZW7Ac3r09pbd3uiv23wCoUuEHawsKlio0Xa5r54szMnSOwyFLFRF/Ig2y7PdoVY04nx/E8UXTfCYAfH9hHmy2oShQHxysqTrE2ZmVTU1u9NuIbWJPFiskuzikTHzgcLsolA6Wmxr+AGyrcnp5GJ57j4MgIyaFGhs9HLsn1BT4paavXSzY9UYV8MlitLBk+zHR1QUsnYfJ6YWZnxQfcbpKIAyR2uxdUe2r33fF4Ttx/bzJdmpkBYPrnnpTIvE/YAuwltLA3NR0xn9cfM5eXM1bNqlkohJp1hiyRCbkeG1fIint41N0dCKBO9qKsbDl4nLD2BurpGWoGB4NPpOfmDhcUKFnbsOV0MBjrwCtZtQHGQYIUQSAKDcyf0Wgi9buLFn83UIV8TPr9ov0oZdBojlZ2yt84x8Yi/UGXMy9Xz5eUUB77SZ/S0sKdo17UY4iILCgnS2PjcePn4ddGQZDgJjVC2d4OJV6xodra5J4OudkyMBCtF6MqVhgyaLXoIDW7kpER7hz7znTkJRLJASZxOP6cCkK0cu6yyy7bxG/BiRf0jj0wWQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOS0wNVQxOTowNzozOSswMDowMFz/8C4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDktMDVUMTk6MDc6MzkrMDA6MDAtokiSAAAAAElFTkSuQmCC",
	// NOT_ALLOWED: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkFEQ0RcmklKgAABE1JREFUWMPVln1MW2UUxs/bD6yAlHsLmi06AoaQgNBLS9lMLx+RISOoWAchM5khujGiDtkMoFFwOlzImAssso8IaGqICSMawnAbX8NCmYXVQTvjguAmJcOWj4Jz68Dee/xjliUY0sFKmb9/78nJ85zzvPd9gU5n9rDszAxtYmZZtqICIAmTUCSC/wkCMIABgKIgGZIBSkqoK3N5zlG9XnowpjJRGhq63gLdG1gCSYQBkhsfL5wmcv67gQGKkhsSCjMy1lvofRtYREuKoUwmIzJyAiUtLdQ38tOsT3U1gFKhVIjF6y3cvQEXMzAIvYSQt8inEF9QQHVxtx/VPjwRc29gCSQLJJCvUj0sEVuxgUWWjVhkY2Sjj4+3DBCaZhiWRfRUQ2yCO3ByYIDX828I1Dk5c6WmIt3ctWtrZWD1G1huIl6OmMcNLOKliHk8Qm4JgXE83NcnDuJLMF+jsbabivpMNttq263dBpbyDoTB2wsLMAxvAm7aNF8O9XAsIuJB2658AyL4GE+cPw8NGE0qenqQIXoSbLUKDsEdCJ2c5G0YgtFTU8JhKADL5CT3q8hXorVa7bPGnzo65+Y8PZf7frThPtDA7gsX7KV//R6gycyE1JHMsyPz854WtFLcR2gzHMOPzGb4TNgs0Wo0ACMFnhD+RGpMZeqQnx+FclTjgQN0OqNTt5tMdAjTykrGxuhS5lV1+5kzgeWxRxLsiYkrN/AC+GDR9euCWOE+QWtamqciEJwd2ZiE/v4Lr5Ncx/fd3eRpAOgoLIQusIGuowN/wXfhVFUV2uEGqfHzE+h5BQ9dXbKtsUfYozt3ujfwGh6GT6anyc9Cjv8jPX3qkNHYUzUx8aDCXTh3iU/y/sXF5AMIwrjwcGeZKA5/VCpn5gez9Af374eNIl+Jtq4O8iEcj/j6QgbRg93p5M/iU9BZUxOwJbJxi4Wm/2MA6/FbCHE4cBIAIDNz2mg0Xtxz9aqnhLsgL5Eh9N++HSKgktTW1v6ZazT2lY6OUoFKxdYUqRR6OaXjVFsbyYCXyTMbNuCHHApbVSr4GoUwJBYLfX3UovK0tHsGduFloIeGBD+QThK/Y4e9Yej53uf0ek8LXxzURTSAkKIgkozjlxbLcsK5We49iEtOts+ay3R7zWZiggDQ3rqFDZAP8xS1VvrcQj/ONKvN585R40wUe7StjbrC7FaPGAyuwyuVRkezbFiYq172CvPis7dVKtfvngqMmVG3s+y6GZBtjD2dEJaSQscz1Wwrx1H18ib2SYeDGpF/pa6IinLVBQUpFCyrVFJ1TLjaMjxMv8+Y2Bad7u5XQlx13ruJ/2X6xuXsnt86O8FCqrA3Lw82k+PYzHEwQQoJ3d9Pfc7ksHutVp7neYBLl0gTxJBYm01kcLb8fTM7+26XexcvWa0QT+HPMDr2ZnDwI0GkH77Ytg3lOIb5NM0bIYtwg4Oz3YOJvY+5Ju/FN5u3+AfzEuPoy5HmsQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOS0wNVQxNzoxMzoxNyswMDowMHxm/wQAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDktMDVUMTc6MTM6MTcrMDA6MDANO0e4AAAAAElFTkSuQmCC",
	// CROSS_RED: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkFEwEeTeej2QAAAopJREFUWMPVmE1oE0EYht9vGohSGwiIplGk0sQfxFNpaApeYukmjRSlQYq1nrLpwYNFpQgVgjYHqXro0WoFiUVRSgLF/Fj12LT1JPaQ2IqX/NBT2qIHodnxku0hoSS72dD4nXfme57dnZl3l3I9zrj4LRzmJ+kT7tls/I3kY2xg4NjvuPXZh0QCDVIb7S6Ld6qrq3ADgBAK4SqSzLG4yNBOBmx0duIBVnG8tZXustHCy1hMHtAw4MM4jcF4HCKs9NRkokFc4aLNxrifBfi7/n4awhoi+Tx8kOi+wSAP2C+RbNbt9no7OqQTAE1GIjIX/LiM/NYWTdI5yno8VDqAhqQURRcW+Cys6DMaMQ3GA9vbTUGk8FYQjv6Mrr+4tbRUb/AyjiI489AvMgqCSYg8mn6+vExVT1BnEaXg8jhSPKHGImrBKwpU26DpCx7zC06nUpFawasW0LqhVvMoFqgVQGtw1QJKgaTz9F3iOztag9csIFfZQVPcr2mMP8H1zU1uoTF+hrHSfZxlpAnJ3ttrisTTM3dWVtT2r1mg4hORS6M7XlpMKwE+g8/UptfzazyF2zpd2QU3kcSoTsf9+EGrer1WfWsWyEy4D4iB7m4yFf7gYCwGN11CsqVFfoXkcwNHkMSr5mbu5H8L4fl5rSKKaoHMIWFtxG23U0G6yMVoVAbfzSrDLEivnU74GDDucNQrayleA7vgxdRatjiVbqM1nuxVC6gFLy2tRSoKaAVetYjCiLKnQL3AlYooDnOVwNWGt3qJ/P8fNI0CrlaEciOuhPgxk+F+PMR7s3m/wUtrz6zlgg7mdJpybS6LNx8KQUCQvtps0qx0mE15PI36W0XqQZDOzs3xPn4K44nEP6RAMeEuNTgbAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA5LTA1VDE5OjAxOjMwKzAwOjAwxHnFugAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wOS0wNVQxOTowMTozMCswMDowMLUkfQYAAAAASUVORK5CYII=",
	// CROSS_BLACK: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYEAYAAACw5+G7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkFEyoMyC4v+AAAAphJREFUWMPNlj9oE2EUwN+7C4YQEr2jYFpbxbroYs6EJG3uohKFpBniElARB4ttdKgu6lQiilC06pJJKCIuQaXQGmxSg9ii0aRKA+KfJSAkbbLlT4cWMb3PxcvQEtL8uSRvvLvv3u/3vnvfPWBu6An/fGaGWdYnhZ5Mhlk/5rUODw5ChwU7ZHDyYwMDTEQf4H9ls8wi9114NT1NwTVkMWIy4Wn0QH93N+rEEepIOCwt6BRwiG7ew/3z83gW7+OITocueEl6zGaKNlAH4bfbDRawAOTzoMDLMKvVSgvaJdLVZTAIgtEIIB7AxNxcmWsPSZDbxSL4RQ2+83i2LWCHOK8g5HIsy3GCQAir1X8VJovFVolU5OjXEz5SKLBPj04Kdy2W2l8gs0jD4O0SaTp4zQnqFJEdXK6ELQdvFoBc4NioiGgSTQCRCMQhDsAw5WNunDzEzw4HdUsRgLelUrXncsPfbn70xeOyVb5SSD1Qbm6psibOyP/N57ddlyq+rr9ie2Y2N5q/7h3Y8Y5IIVPFqWYJbPrFC/hYqSSvwQ67FYqt90kQTwGrUIgZGnFMqWxW3oYFmAD3yLZktYIHesmdcBj3wgQUNRo4BDR5XyhAiUzBmbU1tEEOr6vV1APxJJkKBpv1H6lbQJpa0UtOkMVQqAwufSpXxXM44XRSu+hRmLXbO2bWksArNmetx2irZq16wdsu0izwHYs0OKLIDl6zSK15qoJ36jjd9iarUwQr/kH/n9/A0+Mk5XDkQsvhqD8Wk1tgq0i1GQqYFMcIF1dX21XxalFx1lJzPj6aTtOqXt3xvi82G/jhJ37QasFH/RFzbnf+UuLFpyetq3il2Ehmk+mllRWVfV+q7/DCAnlDfqDK5YJRch42YrF/12iOK1C47YYAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDktMDVUMTk6NDI6MTIrMDA6MDBKLRtvAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA5LTA1VDE5OjQyOjEyKzAwOjAwO3Cj0wAAAABJRU5ErkJggg==",
	CROSS_RED: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAAB3RJTUUH5QkGBAciPw5iwwAAAnZJREFUSMetk19IU3EUx8/57d4KRAiMZvNPLt1EDZKIsHCwgYhjLqq38EXHKF+ionxJh0VFkWkJPRiRBSEYgQxjD+nyiiL5FEJh1HS4uVmCBluJxH67p4e8gyZy7zXP64/z/Xx+v3N++K3Dedc7GQzCe7qMA3l5qaN8LZ3rchV3Bff1dy0twQ5VtK1uxdNmMokfhBzDz0AATuAjalpdZVQOZnydn08v8Sykq6vFSbHGQJKkNOwYeCNX4dAeqkOr0cjkH9BBpQ0N+ACawD03R0NggYTVKi4IUXZvfHyx2/Wl1VVQoBe8HHQvew8bjUKj6GWdo6NKLvyCEJyJRLCdjrH9bjcqDbFeZ5m3t7DQEIEbaJYkugYD8KasDC/RHeoLhXiNIUc45XAUXQ2U9wXicTVwWuQXYHpsDCzwG69UVmbAFjnEnHb7AXyLT3BhAbMDtiuiF6z04VY30Sqy6wjbyyOc6wWrCqiKTJGfWsJh6GbncIhzGqL1f2asAtYssEnkOAzi94kJOgg+WDGbM0FOEPBkLAYz8jA222xqYKWYVgGxSpiCp6kUeYjRbc6zz6mHRDrPOe8RvmJjKqU1V1Vg03K9w3ZstVignoZhMB5XnhwqcB2aS0qE6fQaH5Ykrd+XaQZnL9dHEliytpZepa9zr80GnSDC5/l56v0rqFUEdYO3WK74rfr+FlNREUYNfuGQJMFNSEFFaana98X/BWeXXhHcKfB2RZg8yR+jb2QkA35GfrgfDiuz1QtWqsA34nm+tLhIxenTPOxwZHKVHfkkE38RCDC6CK2QTCQgBLvp4ewsgTzAq+x2JUAveEuRjdwMpwty0Z9M/gFKhhmpnxJVWAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMS0wOS0wNlQwNDowNzozNCswMDowMM7DpBQAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjEtMDktMDZUMDQ6MDc6MzQrMDA6MDC/nhyoAAAAAElFTkSuQmCC",
}

export const DIRECTIONS = {
	RTL: "rtl",
	LTR: "ltr"
}
export const LANGUAGES:{[key:string]:language} = {
	ARABIC_EGYPT: "ar-eg",
	ARABIC_EMIRATES: "ar-em",
	ENGLISH_US: "en-us"
}
export const getLangDirection = (lang:language):direction => {
	switch (lang) {
		case LANGUAGES.ARABIC_EGYPT:
		case LANGUAGES.ARABIC_EMIRATES:
			return "rtl";
		default:
			return "ltr";
	}
}
export const getLangCasing = (lang:language):boolean => {
	switch (lang) {
		case LANGUAGES.ENGLISH_US:
			return true;
		default:
			return false;
	}
}
//date related
export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//Styling related
export const THEMES = {
	LIGHT: "light",
	DARK: "dark",
}
export const BREAKPOINTS = {
	SILVER: "480",
	GOLD: "768",
	PLATINUM: "1024",
	DIAMOND: "1920",
}
export const COLORS = {
	PRIMARY: {d1: "var(--primary-d1)", d2: "var(--primary-d2)", d3: "var(--primary-d3)", d4: "var(--primary-d4)", d5: "var(--primary-d5)"},
	SECONDARY: 'var(--secondary)',
	BACKGROUND: {d1: "var(--background-d1)", d2: "var(--background-d2)", d3: "var(--background-d3)", gradient: "var(--gradient)"},
	GRAY: {d1: "var(--gray-d1)", d2: "var(--gray-d2)"},
	SUCCESS: "var(--success)",
	DANGER: "var(--danger)",
	INFO: "var(--info)",
	WARNING: "var(--warning)",
	TEXT: "var(--text)",
}


//Allowable formats
export const IMAGE_FORMATS = ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png','apng','webp','bmp','ico', 'cur'];
export const VIDEO_FORMATS = ['mp4', 'webm', 'ogg'];
export const APPLICATION_FORMATS = ['pdf']


//Token
export const ObjectToString = (_id:Id,token:string,name:string,email:string,phone:string,countryCode:string,
							   favoriteDevelopersIds:Id[],
							   favoriteProjectsIds:Id[],
							   favoritePropertiesIds:Id[],
) => {
	const user = {
		_id,
		name,
		email,
		phone,
		countryCode,
		token,
		favoriteDevelopersIds,
		favoriteProjectsIds,
		favoritePropertiesIds,
	}
	return JSON.stringify(user)
}
export const StringToObject = (stringObject) => JSON.parse(stringObject)
export const DEFAULT_USER_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MTA3OTQ1MjNkMTk0NTNiOWNmMDBmMzMiLCJwaG9uZSI6IiIsInJvbGVzIjpbImd1ZXN0Il0sImlhdCI6MTYyNzg4NjY3NH0.AIJsJ0CwFevtn7k3Dad0zenCmrBqqFShOEXNLyh_pzY";

//Network Related
// export const API_BASE_URL = "https://api.vu-space-dev.com/api/";
export const API_BASE_URL = "https://api.vu-space.com/api/";
export const ENDPOINTS = {
	HOME: '/home',
	DICTIONARIES: "/dictionaries",
	PROPERTIES: "/properties",
	PROJECTS: '/projects',
	DEVELOPERS: '/developers',
	LOGIN: '/login',
	REGISTER: '/register',
	USERS: '/users',
	FORGOT_PASSWORD: '/forgetPassword',
	RESET_PASSWORD: '/resetPassword',
	FEATURES: '/projectFeatures',
	BRANDS: '/brands',
	FLOORS: '/floors',
	DOORS: '/doors',
	WALLS: '/walls',
	WINDOWS: '/windows',
	AMENITIES: '/propertyAmenities',
	PROPERTY_TYPES: '/propertyTypes',
	PERMISSIONS: '/permissions',
	ROLES: '/roles',
	CATEGORIES: '/categories',
	SUBCATEGORIES: '/subCategories',
	COUNTIRES: '/countries',
	CITIES: '/cities',
	DISTRICTS: '/districts',
	CONTACTS: '/contacts',
	CONTACTS_LEADS: '/contacts/leads',
	CHECK_USER_EXISTENCE: '/checkUserExistence',
	SEND_VERIFICATION_CODE: '/verify/send',
	RE_SEND_VERIFICATION_CODE: '/verify/resendCode',
}

// GOOGLE MAPS OLD API
// export const GOOGLE_API = 'AIzaSyDVaDo9y5cL1z7SCJd7Llmr_DPZ8N7ojk4'
// GOOGLE MAPS API
export const GOOGLE_API = 'AIzaSyD_tbTv_Wlkp91k1ga6q2W3yRTAoR86cr0'




